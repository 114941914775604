import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import "./about.css";

interface AboutProps {
  innerRef: React.Ref<unknown>;
}

const About: React.FC<AboutProps> = ({ innerRef }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Stack
        sx={{ background: "#FEEA4A", minHeight: "170vh" }}
        alignItems="center"
        justifyContent="center"
        ref={innerRef}
        spacing={4}
        paddingX={{ xs: "3rem", md: "5rem" }}
        paddingBottom="7rem"
        paddingTop="1rem"
      >
        <img
          style={{
            width: isMd ? "20vw" : "40vw",
            borderRadius: "50%",
            boxShadow: "0 10px 10px -10px black",
            marginInline: "auto",
            transform: isMd ? "translate(-10%, 0)" : "none",
          }}
          src={require("../../Media/about-page.webp")}
          alt="Logo"
        />
        <Stack direction={{ xs: "column", md: "row" }} spacing={5} justifyContent="center">
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} spacing={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: "1.8rem", md: "2.5rem" }}
              fontWeight={"bold"}
              textAlign="center"
              fontFamily="Poppins"
            >
              Who Are We?
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              textAlign={{ xs: "center", md: "justify" }}
            >
              We are an Educational Institution based in <u>Kinshasa, DRC.</u> We promote individuality,
              facilitate curiosity, celebrate independence, encourage collaboration and enable critical
              thinking to allow our little geniuses to reach their highest potential.
            </Typography>
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} spacing={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: "1.8rem", md: "2.5rem" }}
              fontWeight={"bold"}
              textAlign="center"
              fontFamily="Poppins"
            >
              Our relationship with Parents
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              textAlign={{ xs: "center", md: "justify" }}
            >
              Our school is not just a community of children. We view our relationship with you as a
              partnership and work together for the benefit of your child, because we strongly believe that
              when home and school work together in harmony, the child grows best.
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} spacing={5} justifyContent="center">
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} spacing={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: "1.8rem", md: "2.5rem" }}
              fontWeight={"bold"}
              textAlign="center"
              fontFamily="Poppins"
            >
              Our Mission
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              textAlign={{ xs: "center", md: "justify" }}
            >
              To provide a nurturing and engaging learning environment where our little geniuses form a strong
              foundation that enables them to achieve their full potential.
            </Typography>
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} spacing={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: "1.8rem", md: "2.5rem" }}
              fontWeight={"bold"}
              textAlign="center"
              fontFamily="Poppins"
            >
              Teaching Ideology
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              textAlign={{ xs: "center", md: "justify" }}
            >
              It is not uncommon to see four-year-olds in our school reading and six-year-olds completing
              multiplication problems. We present skills at the time when children are most ready to learn.
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", md: "row" }} spacing={5} justifyContent="center">
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} spacing={1}>
            <Typography
              variant="h2"
              fontSize={{ xs: "1.8rem", md: "2.5rem" }}
              fontWeight={"bold"}
              textAlign="center"
              fontFamily="Poppins"
            >
              Why Montessori?
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              textAlign={{ xs: "center", md: "justify" }}
            >
              Montessori education, thriving for over a century, aligns with modern parents' goals of
              nurturing capable, self-aware, and productive individuals through a child-centered approach and
              thoughtfully designed learning environments.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <div className="second-curves" style={{ marginBottom: "-0.1rem" }}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default About;
