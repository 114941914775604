import Landing from "./Landing/Landing";
import NavBar from "./NavBar/NavBar";
import About from "./AboutPage/About";
import Cta from "./Cta/Cta";
import Footer from "./Footer/Footer";
import React, { useCallback, useRef, useState } from "react";
import { Stack } from "@mui/material";

const MarketingPage = () => {
  const homeRef = useRef<null | HTMLDivElement>(null);
  const aboutRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);

  return (
    <Stack>
      <NavBar homeRef={homeRef} aboutRef={aboutRef} contactRef={contactRef} />
      <Landing innerRef={homeRef} />
      <About innerRef={aboutRef} />
      <Cta innerRef={contactRef} />
      <Footer />
    </Stack>
  );
};

export default MarketingPage;
