import { createTheme } from "@mui/material/styles/";

export const theme = createTheme();
theme.typography = {
  ...theme.typography,
  fontWeightBold: 600,
  fontFamily: "Poppins",
};

theme.palette = {
  ...theme.palette,
  primary: {
    ...theme.palette.primary,
  },
  secondary: {
    ...theme.palette.secondary,
  },
  background: {
    ...theme.palette.background,
    default: "#222",
  },
};
