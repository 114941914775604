import { Button, ButtonProps, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./landingpage.css";
import React, { useEffect, useState } from "react";

interface LandingProps {
  innerRef: React.Ref<unknown>;
}

const Landing: React.FC<LandingProps> = ({ innerRef }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [bgIndex, setBgIndex] = useState<number>(0);
  const images = [
    require("../../Media/CarouselPictures/Annual.webp"),
    require("../../Media/CarouselPictures/Phonics2.webp"),
    require("../../Media/CarouselPictures/Yoga.webp"),
    require("../../Media/CarouselPictures/Matching.webp"),
    require("../../Media/CarouselPictures/Puzzles.webp"),
  ];

  useEffect(() => {
    // set a timer
    const interval = setInterval(() => {
      setBgIndex((index) => {
        if (index === images.length - 1) {
          return 0;
        }
        return index + 1;
      });
    }, 4000);

    // don't forget to clear in cleanup
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Stack
        ref={innerRef}
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          paddingInline: "5vw",
          background: "#58D6CE",
          color: "#fff",
          minHeight: "100vh",
          maxWidth: "100vw",
        }}
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 2, md: 4 }}
      >
        <Stack
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: { xs: "flex-start", md: "center" },
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            textAlign: { xs: "center", md: "left" },
          }}
          spacing={{ xs: 2, md: 2 }}
        >
          <Stack
            sx={{ marginBottom: { xs: "0rem", md: "1rem" } }}
            spacing={{ xs: 0, md: 2 }}
            width={{ xs: "90%", md: "100%" }}
          >
            <Typography
              variant="h3"
              fontSize={{ xs: "1.5rem", sm: "2rem", md: "3.2rem" }}
              fontWeight={{ xs: 600, md: 800 }}
              sx={{ marginBottom: { xs: "0rem", md: "-1rem" } }}
              fontFamily="IBM Plex Sans"
            >
              The art of teaching is the art of assisting discovery.
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "0.9rem", md: "1.5rem" }}
              sx={{ paddingRight: { xs: "0", md: "1rem" } }}
              fontWeight={200}
              fontFamily={"Poppins"}
            >
              Mark Van Doren
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "1rem", md: "1.5rem" }}
              fontWeight={{ xs: 400, md: 400 }}
              fontFamily={"Poppins"}
              lineHeight={{ xs: "1rem", md: "1.5rem" }}
              color="#2C6B67"
              textAlign={{ xs: "center", md: "left" }}
              paddingTop={{ xs: "0.5rem", md: "1rem" }}
            >
              At G.S. Little Genius we don't fit your child to a mold but help them create their own. Your
              child's adventure begins here.
            </Typography>
          </Stack>
          <Stack
            width="100%"
            alignItems="center"
            justifyContent={{ xs: "center", md: "space-between" }}
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 1, md: 2 }}
          >
            <StyledLink href="tel:0897019881">
              <StyledButton secondary>Call us</StyledButton>
            </StyledLink>
            <StyledLink href="mailto:gslittlegeniuskinshasa@gmail.com">
              <StyledButton>Email us</StyledButton>
            </StyledLink>
          </Stack>
          <StyledLink
            href={
              "https://firebasestorage.googleapis.com/v0/b/gslittlegenius.appspot.com/o/Calendar%2F2024-25_Calendar_GSLittleGeniusKinshasa.pdf?alt=media&token=404ce68a-06c2-4c26-a5fa-49bdb3cdec08"
            }
            target="_blank"
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            &#x1F4C5; View Calendar for 2024/25
          </StyledLink>
        </Stack>
        <Container
          sx={{
            minHeight: { xs: "40vh", md: "65vh" },
            width: "100%",
            flex: { xs: 0.75, md: 1.5 },
            background: `url(${images[bgIndex]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2rem",
            border: `${isMd ? "10px" : "5px"} solid #62DBD3`,
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.322)",
            transition: "all 0.8s ease",
          }}
        />
      </Stack>
      <div
        className="custom-shape-divider-bottom-1643407376"
        style={{ marginBottom: "-0.1rem", marginTop: isMd ? "-92px" : "-65px" }}
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          style={{ height: isMd ? "92px" : "65px" }}
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default Landing;

interface StyledButtonProps extends ButtonProps {
  secondary?: boolean;
}

const StyledButton: React.FC<StyledButtonProps> = ({ children, secondary = false, ...props }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Button
      sx={{
        "&:hover": isMd
          ? {
              background: secondary ? "#58D6CE" : "#fff",
              cursor: "pointer",
              fontSize: "1.6rem",
              transform: "scale(1.05)",
              boxShadow: "0px 10px 2px rgba(0, 0, 0, 0.322)",
            }
          : {},
        background: secondary ? "#58D6CE" : "#fff",
        color: !secondary ? "#58D6CE" : "#fff",
        border: "none",
        outline: secondary ? "2px solid white" : "none",
        fontSize: { xs: "1rem", md: "1.5rem" },
        borderRadius: "1.5rem",
        padding: { xs: "0.25, 0.5rem", md: "0.5rem 1rem" },
        transition: "all 0.3s ease",
        fontFamily: "IBM Plex Sans",
        width: "100%",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const StyledLink: React.FC<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = ({ children, style, className, ...props }) => {
  return (
    <a
      style={{
        textDecoration: "none",
        color: "inherit",
        flex: 1,
        width: "100%",
        ...style,
      }}
      {...props}
    >
      {children}
    </a>
  );
};
