import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { createMessage } from "../../Services/firebase";
import { allFalsy } from "../../helperMethods";
import MessageSent from "../../Media/MessageSent.svg";
import { ContactFormDetails, ContactFormErrors, Message } from "../../types";

const phoneRegEx = /^\+?([0-9]{3})\)?([0-9]{9})$/;
const emptyContactForm = { title: "", content: "", email: "", contact: "" };

const ContactForm: React.FC = () => {
  const [formDetails, setFormDetails] = useState<ContactFormDetails>(emptyContactForm);
  const [formErrors, setFormErrors] = useState<ContactFormErrors>({
    title: null,
    content: null,
    email: null,
    contact: null,
  });

  const addMessage = useMutation(createMessage);

  const anyErrors = !allFalsy(formErrors);

  const handleAdd = (data: Message) => {
    addMessage.mutate(
      { data },
      {
        onSuccess: () => {
          setFormDetails(emptyContactForm);
          setFormErrors((errors) => ({ ...errors, content: null }));
        },
        onError: (e) => {
          console.log(e);
          setFormErrors((errors) => ({ ...errors, content: "Something went wrong, please try again." }));
        },
      }
    );
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormDetails((form) => ({ ...form, title: e.target.value }));
    validateTitle(e.target.value);
  };

  const validateTitle = (title: string) => {
    if (!title) {
      setFormErrors((errors) => ({ ...errors, title: "Please enter a title." }));
      return false;
    }

    setFormErrors((errors) => ({ ...errors, title: null }));
    return true;
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormDetails((form) => ({ ...form, content: e.target.value }));
    validateContent(e.target.value);
  };

  const validateContent = (content: string) => {
    if (!content) {
      setFormErrors((errors) => ({ ...errors, content: "Please enter a message." }));
      return false;
    }

    setFormErrors((errors) => ({ ...errors, content: null }));
    return true;
  };

  const handleContactChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormDetails((form) => ({ ...form, contact: e.target.value }));
    validateContact("+243" + e.target.value);
  };

  const validateContact = (contact: string) => {
    if (!contact) {
      setFormErrors((errors) => ({
        ...errors,
        contact: "Please enter a contact number.",
      }));
      return false;
    }

    if (!contact.match(phoneRegEx)) {
      setFormErrors((errors) => ({
        ...errors,
        contact: "Please enter a valid contact number.",
      }));
      return false;
    }

    setFormErrors((errors) => ({ ...errors, contact: null }));
    return true;
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormDetails((form) => ({ ...form, email: e.target.value }));
    validateEmail(e.target.value);
  };

  const validateEmail = (email: string) => {
    if (!email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setFormErrors((errors) => ({
        ...errors,
        email: "Please enter a valid Email.",
      }));
      return false;
    }

    setFormErrors((errors) => ({ ...errors, email: null }));
    return true;
  };

  const validateForm = (form: ContactFormDetails) => {
    const allValidation = [
      validateTitle(form.title),
      validateContent(form.content),
      validateContact("+243" + form.contact),
      validateEmail(form.email),
    ];
    return allValidation.every((v) => v);
  };

  const handleSubmit = () => {
    if (!validateForm(formDetails)) {
      return;
    }
    handleAdd({ ...formDetails, dateReceived: Date.now(), contact: "+243" + formDetails.contact });
  };

  return (
    <Card
      sx={{
        padding: { xs: "0.5rem", md: "2rem" },
        width: { xs: "100%", md: "50%" },
        borderRadius: { xs: "1rem", md: "2rem" },
        minHeight: "29.5rem",
      }}
    >
      <CardContent>
        <Stack
          spacing={addMessage.isSuccess ? 4 : 2}
          alignItems="center"
          justifyContent="center"
          sx={{
            transition: "all 0.5s ease",
            padding: { xs: "auto", md: "2rem 1rem" },
            minHeight: "26rem",
          }}
        >
          {addMessage.isSuccess && (
            <>
              <img src={MessageSent} style={{ width: "60%" }} alt={"Envelope with Green Tick"} />
              <Typography variant="body1" color="#999999" fontFamily="Poppins">
                Thank you for your message, we will get back to you soon.
              </Typography>
            </>
          )}
          {!addMessage.isSuccess && (
            <>
              <Typography variant="caption" fontSize={"1rem"} color={"rgba(0,0,0,0.6)"}>
                Please ensure your contact details are correct.
              </Typography>
              <TextField
                label="Your Email"
                value={formDetails.email}
                onChange={handleEmailChange}
                sx={{ minWidth: "100%" }}
                error={!!formErrors.email}
                helperText={formErrors.email}
                size="small"
              />
              <TextField
                label="Your Contact Number"
                value={formDetails.contact}
                onChange={handleContactChange}
                sx={{ minWidth: "100%" }}
                error={!!formErrors.contact}
                helperText={formErrors.contact}
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+243</InputAdornment>,
                }}
              />
              <TextField
                label="Title"
                value={formDetails.title}
                onChange={handleTitleChange}
                sx={{ minWidth: "100%" }}
                error={!!formErrors.title}
                helperText={formErrors.title}
                size="small"
              />
              <TextField
                label="Message"
                value={formDetails.content}
                onChange={handleContentChange}
                sx={{ minWidth: "100%" }}
                error={!!formErrors.content}
                helperText={formErrors.content}
                multiline
                minRows={5}
                size="small"
              />
              <Button
                onClick={handleSubmit}
                sx={{ background: "#F1594F", width: "100%" }}
                variant="contained"
                disabled={anyErrors}
              >
                Send Message
              </Button>
            </>
          )}
          <Backdrop open={addMessage.isLoading}>
            <CircularProgress />
          </Backdrop>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
