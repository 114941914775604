import "./footer.css";
import WebsiteLogo from "../../Media/WebsiteLogo.svg";
import { Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack spacing={2} sx={{ padding: { xs: "2rem", md: "2rem 4rem" } }}>
      <Stack
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "flex-end" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, md: 0 }}
      >
        <img style={{ width: isMd ? "20%" : "100%" }} src={WebsiteLogo} alt="Logo"></img>
        <a
          href="https://www.linkedin.com/in/kaifkutchwala"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Typography fontWeight={400} fontFamily={"Poppins"}>
            Website Made By <span style={{ textDecoration: "underline" }}>Kaif Kutchwala</span>
          </Typography>
        </a>
      </Stack>
      <Divider variant="fullWidth" flexItem />
      <Stack spacing={2}>
        <Stack direction="row" spacing={3} justifyContent="center">
          <a
            href="https://www.youtube.com/@g.s.littlegeniusschool4374"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ border: "2px solid #F1594F", padding: "0.4rem" }}
            >
              <FaYoutube size="2rem" style={{ transition: "all 0.3s ease" }} color="#F1594F" />
            </IconButton>
          </a>

          <a
            href="https://web.facebook.com/gslgkinshasa"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ border: "2px solid #4566AB", padding: "0.4rem" }}
            >
              <FaFacebook size="2rem" style={{ transition: "all 0.3s ease" }} color="#4566AB" />
            </IconButton>
          </a>

          <a
            href="https://www.instagram.com/gslittlegeniusschoolkinshasa/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ border: "2px solid purple", padding: "0.4rem" }}
            >
              <FaInstagram size="2rem" style={{ transition: "all 0.3s ease" }} color="purple" />
            </IconButton>
          </a>
        </Stack>
        <Typography
          variant="caption"
          fontSize={{ xs: "0.7rem", md: "1rem" }}
          fontWeight={400}
          fontFamily={"Poppins"}
          textAlign={"center"}
        >
          Copyright © 2023 G.S. Little Genius School Kinshasa
        </Typography>
        <Typography
          variant="caption"
          fontSize={{ xs: "0.7rem", md: "1rem" }}
          fontWeight={400}
          fontFamily={"Poppins"}
          textAlign={"center"}
        >
          ALL RIGHTS RESERVED
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
