import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  doc,
  query,
  limit,
} from "firebase/firestore";

import { Message } from "../types";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const messagesDbName = process.env.REACT_APP_M_DB_NAME;

// Messages
//#region

export async function getMessages() {
  const messagesRef = collection(db, messagesDbName!);
  const messagesQuery = query(messagesRef, limit(100));
  const messagesSnapshot = await getDocs(messagesQuery);
  const messagesList = messagesSnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });

  return messagesList;
}

interface createMessageInput {
  data: Message;
}

export async function createMessage({ data }: createMessageInput) {
  addDoc(collection(db, messagesDbName!), data)
    .then((r) => {
      return true;
    })
    .catch((e) => {
      return false;
    });
}

interface setMessageInput {
  id: string;
  data: Message;
}
export async function setMessage({ id, data }: setMessageInput) {
  setDoc(doc(db, messagesDbName!, id), data)
    .then((r) => {
      return true;
    })
    .catch((e) => {
      return false;
    });
}

interface delMessageInput {
  id: string;
}

export async function delMessage({ id }: delMessageInput) {
  deleteDoc(doc(db, messagesDbName!, id))
    .then((r) => {
      return true;
    })
    .catch((e) => {
      return false;
    });
}

//#endregion
