import "./navbar.css";
import Logo from "../../Media/Logo.svg";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import useOnScreen from "../../Hooks/useOnScreen";
import { AppBar, Box, IconButton, Stack, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { StyledLink } from "../Landing/Landing";

type Tabs = "home" | "about" | "contact";

function vhToPixels(vh: number) {
  return "-" + Math.round(window.innerHeight / (100 / vh)) + "px";
}

interface NavBarProps {
  homeRef: React.MutableRefObject<HTMLDivElement | null>;
  aboutRef: React.MutableRefObject<HTMLDivElement | null>;
  contactRef: React.MutableRefObject<HTMLDivElement | null>;
}

const NavBar: React.FC<NavBarProps> = ({ homeRef, aboutRef, contactRef }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const homeIsVisible = useOnScreen(homeRef, vhToPixels(40));
  const aboutIsVisible = useOnScreen(aboutRef, vhToPixels(40));
  const contactIsVisible = useOnScreen(contactRef, vhToPixels(40));

  const [activeTab, setActiveTab] = useState<Tabs>("home");

  const handleHomeClick = () => {
    setActiveTab("home");
    homeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleAboutClick = () => {
    setActiveTab("about");
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleContactClick = () => {
    setActiveTab("contact");
    contactRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (homeIsVisible && activeTab !== "home") setActiveTab("home");
    if (aboutIsVisible && activeTab !== "about") setActiveTab("about");
    if (contactIsVisible && activeTab !== "contact") setActiveTab("contact");
  }, [homeIsVisible, aboutIsVisible, contactIsVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return isMd ? (
    <LargeScreenNavBar
      handleHomeClick={handleHomeClick}
      handleAboutClick={handleAboutClick}
      handleContactClick={handleContactClick}
      homeIsVisible={homeIsVisible}
      aboutIsVisible={aboutIsVisible}
      contactIsVisible={contactIsVisible}
      activeTab={activeTab}
    />
  ) : (
    <SmallScreenNavBar />
  );
};

const SmallScreenNavBar: React.FC = () => {
  const [scrollY, setScrollY] = useState<number>(0);

  const handleScroll = useCallback((e: Event) => {
    setScrollY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrolled = scrollY > 50;

  return (
    <Box sx={{ background: "#58D6CE", height: "14vh", marginBottom: "-0.1rem" }}>
      <AppBar
        position="fixed"
        sx={{
          paddingBlock: scrolled ? "0.5rem" : "1rem",
          background: scrolled ? "#fff" : "none",
          boxShadow: scrolled ? "0px 0px 2px 5px rgba(0,0,0,0.05)" : "none",
          transition: "all 0.3s ease",
          borderBottomRightRadius: "2rem",
          borderBottomLeftRadius: "2rem",
        }}
      >
        <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <img
            style={{
              height: "auto",
              width: "50%",
              filter: scrolled ? "none" : "drop-shadow(8px 8px 0px rgba(0,0,0,0.3))",
              transition: "all 0.3s ease",
            }}
            src={Logo}
            alt="Logo"
          />
          <Stack direction={"row"}>
            <a
              href="https://www.youtube.com/@g.s.littlegeniusschool4374"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <IconButton size="large" edge="start" color="inherit" aria-label="menu">
                <FaYoutube
                  size="2rem"
                  color={scrolled ? "#F1594F" : "inherit"}
                  style={{ transition: "all 0.3s ease" }}
                />
              </IconButton>
            </a>

            <a
              href="https://web.facebook.com/gslgkinshasa"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <IconButton size="large" edge="start" color="inherit" aria-label="menu">
                <FaFacebook
                  size="2rem"
                  color={scrolled ? "#4566AB" : "inherit"}
                  style={{ transition: "all 0.3s ease" }}
                />
              </IconButton>
            </a>
            <a
              href="https://www.instagram.com/gslittlegeniusschoolkinshasa/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <IconButton size="large" edge="start" color="inherit" aria-label="menu">
                <FaInstagram
                  size="2rem"
                  color={scrolled ? "purple" : "inherit"}
                  style={{ transition: "all 0.3s ease" }}
                />
              </IconButton>
            </a>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

interface LargeScreenNavBarProps {
  handleHomeClick: () => void;
  handleAboutClick: () => void;
  handleContactClick: () => void;
  homeIsVisible: boolean;
  aboutIsVisible: boolean;
  contactIsVisible: boolean;
  activeTab: Tabs;
}

const LargeScreenNavBar: React.FC<LargeScreenNavBarProps> = ({
  handleHomeClick,
  handleAboutClick,
  handleContactClick,
  homeIsVisible,
  aboutIsVisible,
  contactIsVisible,
  activeTab,
}) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        paddingBlock: "1rem",
        paddingInline: "2.5rem",
        background: "none",
        boxShadow: "none",
      }}
      className="navbar"
    >
      <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <img className="main-logo" src={Logo} alt="Logo" style={{ height: "4rem" }}></img>
        <ul style={{ color: aboutIsVisible ? "#000" : "inherit" }}>
          <li
            className={activeTab === "home" || homeIsVisible ? "active" : undefined}
            onClick={handleHomeClick}
          >
            home
          </li>
          <li
            className={activeTab === "about" || aboutIsVisible ? "active" : undefined}
            onClick={handleAboutClick}
          >
            about
          </li>
          <li
            className={activeTab === "contact" || contactIsVisible ? "active" : undefined}
            onClick={handleContactClick}
          >
            contact us
          </li>
          <div className="nav-btns">
            <button className="nav-btn youtube">
              <a href="https://www.youtube.com/@g.s.littlegeniusschool4374" target="_blank" rel="noreferrer">
                <FaYoutube />
              </a>
            </button>
            <button className="nav-btn facebook">
              <a href="https://web.facebook.com/gslgkinshasa" target="_blank" rel="noreferrer">
                <FaFacebook />
              </a>
            </button>
            <button className="nav-btn instagram">
              <a
                href="https://www.instagram.com/gslittlegeniusschoolkinshasa/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </button>
          </div>
        </ul>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
